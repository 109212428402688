import React from 'react'
import { Contact } from '../components/pages/contact'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const JPContactPage = (props: any) => {
  const siteMetadata = useSiteMetadata()
  return <Contact {...props} en={false} siteMetadata={siteMetadata} />
}

export default JPContactPage
