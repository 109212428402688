import React from 'react'
import styled, { css } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout, Center } from '../layout'
import Seo from '../seo'

import { Location } from '../../interfaces/gatsby'
import { SiteMetadata } from '../../interfaces/siteMetadata'

interface Props {
  en: boolean
  location: Location
  siteMetadata: SiteMetadata
}

export const Contact = ({ en, location, siteMetadata }: Props) => {
  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata}>
      <Seo lang={en ? 'en' : 'ja'} />
      <Center style={{ minHeight: 'inherit' }}>
        <ContactCard>
          <ImageStyle>
            <StaticImage
              layout="fixed"
              src="../../images/profile-pic.jpg"
              width={100}
              height={100}
              quality={100}
              alt="Profile picture"
            />
          </ImageStyle>
          <div>
            <h2>Zett</h2>
            <p>Photographer / Web engineer</p>
            <p>
              contact: <a href={`https://twitter.com/${siteMetadata.social.twitter}`}>Twitter</a>
            </p>
          </div>
        </ContactCard>
      </Center>
    </Layout>
  )
}

const ContactCard = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  min-height: inherit;
  margin: 0 auto;
`

const ImageStyle = styled.div`
  div {
    box-shadow: none;
    margin-right: 20px;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
  }

  img {
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
  }
` as any
